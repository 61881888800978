<template>
  <div class="home">
    <div class="bg">
      <div class="img"></div>
    </div>
    <div class="row">
      <el-row>
        <div class="top"></div>
      </el-row>
      <el-row>
        <div class="middle">
          <div>
            <h1>卖艺不卖身的正经全栈攻城狮</h1>
            <p>
              <small>↑↑↓↓←→BA</small>
            </p>
          </div>
        </div>
      </el-row>
      <el-row>
        <div class="bottom">
          <span class="bc bc-down"></span>
        </div>
      </el-row>
    </div>
    <div class="row">
      <el-row type="flex" align="middle" justify="center" style="flex-wrap: wrap; height: 100%;">
        <el-col :span="24">
          <el-row type="flex" align="middle" justify="center">
            <el-col :xs="24" :sm="11" :md="6" :lg="6" :xl="6">
              <img src="../assets/aircraft-qrcode.jpg" alt width="200px" />
            </el-col>
            <el-col
              :xs="0"
              :sm="2"
              :md="1"
              :lg="1"
              :xl="1"
              style="width: 2px; height: 220px; background-color: #ddd;"
            ></el-col>
            <el-col :xs="24" :sm="11" :md="6" :lg="6" :xl="6">
              <h2>微信小游戏：狂浪战舰</h2>
              <p style="color: #888;">又一款无聊至极的飞机大战游戏</p>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24">
          <el-row type="flex" align="middle" justify="center">
            <el-col :xs="24" :sm="11" :md="6" :lg="6" :xl="6">
              <img src="../assets/gi-qrcode.jpg" alt width="200px" />
            </el-col>
            <el-col
              :xs="0"
              :sm="2"
              :md="1"
              :lg="1"
              :xl="1"
              style="width: 2px; height: 220px; background-color: #ddd;"
            ></el-col>
            <el-col :xs="24" :sm="11" :md="6" :lg="6" :xl="6">
              <h2>微信小程序：GI查询工具</h2>
              <p style="color: #888;">常用食物的升糖指数查询</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { store } from "../store";
export default {
  name: "home",
  components: {
    // HelloWorld
  },
  mounted() {
    // 切换主题
    store.setTheme("light");
  }
};
</script>

<style lang="less" slot-scope>
@keyframes loopMoveDown {
  0% {
    bottom: 20px;
  }
  10% {
    bottom: 5px;
  }
  25% {
    bottom: 20px;
  }
  100% {
    bottom: 20px;
  }
}
.home {
  margin-top: 0px; // header 距离
}
.bg {
  // background-color: #ddd;
  // background-image: url("../assets/me.jpg");
  height: 100vh;
  width: 100%;
  position: absolute;
  // z-index: -1;
  overflow: hidden;
  .img {
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center top;
    background-size: cover;
    // background-image: url("../assets/me.jpg");
    // background-repeat: no-repeat;
    // background-size: cover;
    opacity: 0.6;
    overflow: hidden;
    // filter: saturate(180%) blur(10px);
    &::after {
      background-color: rgba(0, 0, 0, 0.3);
      background-image: url("../assets/me.jpg");
      background-position: center top;
      background-size: cover;
      backdrop-filter: blur(10px);
      filter: saturate(180%) blur(10px);
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.8);
      z-index: -1;
      margin: -30px;
    }
  }
}

.row {
  width: 100%;
  height: 100vh;
  text-align: center;
  display: table;
}
.top {
  // width: 100%;
  display: table-cell;
  vertical-align: top;
}
.middle {
  display: table;
  vertical-align: middle;
  height: 100vh;
  width: 100%;
  div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    // font-size: 3rem;
    font-weight: bold;
    opacity: 0.8;
    h1 {
      font-size: 4rem;
    }
    small {
      opacity: 0.6;
    }
  }
}
.bottom {
  position: absolute;
  animation: loopMoveDown 2s infinite;
  width: 100%;
  span {
    // position: absolute;
    color: #333;
    bottom: 20px;
    font-size: 1.5rem;
  }
}
</style>
